<template>
  <div class="googleMap"></div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'GoogleMap',
  props: { geolocation: Object },
  data: function () {
    return {
      searchOpen: false
    }
  },
  mounted () {
    var geolocation = { lat: this.geolocation.latitude, lng: this.geolocation.longitude }
    var map = new google.maps.Map(
      document.getElementsByClassName('googleMap')[0], { zoom: 15, center: geolocation })
    new google.maps.Marker({ position: geolocation, map: map })
  }
}
</script>

<style scoped lang="stylus">
.googleMap
  margin 30px 0
  display block
  width 100%
  height 400px
  background-color #eee

</style>
